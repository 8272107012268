import "moment/locale/th";

import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  api,
  google_api_key,
  header_token,
  img_url,
} from "../../config";
import { compose, withProps } from "recompose";

import Axios from "axios";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { ModalShowImage } from "../../ModalShowImage";
import ReactPlayer from 'react-player/lazy'
import Row from 'react-bootstrap/Row';
import Select from "react-select";
import { checkImageFlowOld } from '../../utils/Function'
import { convertPaidByText } from "../../utils/Function"
import moment from "moment";
import shortid from "shortid";
import swal from "sweetalert";

const order_status = [
  { value: 1, label: "ออเดอร์ใหม่" },
  { value: 4, label: "กำลังจัดส่ง" },
  { value: 5, label: "ส่งสำเร็จ" },
  { value: 0, label: "ยกเลิก" },
  { value: 6, label: "ส่งไม่สำเร็จ" },
  { value: 3, label: "กำลังดำเนินการ" },
  { value: 2, label: "เข้ารับสินค้า" },
];

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${google_api_key}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // console.log('re-render');
  return (
    <GoogleMap defaultCenter={props.point} zoom={17} center={props.point}>
      <Marker position={props.point} draggable={false} />
    </GoogleMap>
  );
});

export const OrderCarDetail = () => {
  const { id } = useParams();

  const [img, setimg] = useState("");
  const [showVideo, setShowVideo] = useState("");
  const [point, setpoint] = useState({ lat: 13.674351, lng: 100.517556 });
  const [chatHistory, setChatHistory] = useState([])

  const [data, setdata] = useState({
    user: {},
    to: {
      latitude: 16.409936252252667,
      longitude: 103.06710188750003,
      address: "",
      province_id: "",
      district_id: "",
      sub_district_id: "",
      province: {
        id: "",
        code: "",
        name_th: "",
        name_en: "",
        short_name_en: "",
        short_name_th: "",
      },
      district: {
        id: "",
        code: "",
        name_th: "",
        name_en: "",
      },
      sub_district: {
        id: "",
        zip_code: "",
        name_en: "",
        name_th: "",
      },
    },
    rider: { id: "", employee_type: {}, team: {} },
    car_service_start_point: {
      picture: []
    }
  });
  const [log, setlog] = useState([]);
  const [menu, setmenu] = useState([]);
  const [addon_option, setaddon_option] = useState([]);
  const [add_on_option, setadd_on_option] = useState({});
  const [select_order, setselect_order] = useState({});
  const [order_option, setorder_option] = useState([]);
  const [rider_option, setrider_option] = useState([]);
  const [select_rider, setselect_rider] = useState({});
  const [order_status_filter, setorder_status_filter] = useState(order_status);
  const [receiveImages, setReceiveImage] = useState([])
  const [signatureImage, setSignatureImage] = useState({})

  const [status, setstatus] = useState({});
  const [isShowImagePreview, setIsShowImagePreview] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const [show, setShow] = useState(false);
  const [isSelectedOption, setIsSelectedOption] = useState(null);
  const [destinations, setDestinations] = useState([]);
  const [destinationSeleted, setDestinationSelected] = useState(null);
  const [carServiceId, setCarServiceId] = useState(null);
  const [isFilterRiderSuccessed, setFilterRiderSuccessed] = useState(false)

  const handleClose = () => {
    setShow(false)
    setReceiveImage([{}])
    setSignatureImage({})
  };
  const handleShowForm = () => {
    setShow(true)
  };

  const handleSubmit = () => {
    let isValidated = true
    if (receiveImages.length === 0 || (signatureImage === null || signatureImage === undefined || signatureImage.preview === undefined)) {
      isValidated = false
    }

    if (isValidated) {
      swal({
        title: `ต้องการเปลี่ยนสถานะเป็น ${isSelectedOption.label} หรือไม่ ?`,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willApprove) => {
        if (willApprove) {
          const groupImage = []
          if (receiveImages.length > 0) {
            for (let index = 0; index < receiveImages.length; index++) {
              const img = receiveImages[index];
              groupImage.push(img.base64)
            }
          }

          const desId = destinationSeleted !== null || destinationSeleted !== undefined ? destinationSeleted.value : ""

          let is_success = await confirmChangeStatus(
            isSelectedOption.value,
            id,
            groupImage,
            signatureImage.base64,
            desId
          );

          setShow(false)

          if (is_success) {
            getOrder();
            getOrderLog();
            getDataOnlineRider();
            swal("เสร็จสิ้น !!", {
              icon: "success",
            });
          } else {
            swal("เกิดข้อผิดพลาด !!", {
              icon: "error",
            });
          }
        }
      });
    } else {
      swal(receiveImages.length === 0 ? "กรุณาเพิ่มรูปภาพอย่างน้อย 1 รูป" : "กรุณาเพิ่มรูปลายเซ็น", {
        icon: "error",
      });
    }
  }

  const getOrder = async () => {
    try {
      let { data } = await Axios.get(`${api}/admin/order/${id}`, header_token);
      setmenu(data.car_service_addons);
      const users = []
      if (data.user_id !== null && data.user_id !== '') {
        const { data: userDetail } = await Axios.get(
          `${api}/admin/user/profile/${data.user_id}`,
          header_token
        );

        users.push(userDetail)
      }

      if (data.rider_id !== null && data.rider_id !== '') {
        const { data: riderDetail } = await Axios.get(
          `${api}/admin/user/profile/${data.rider_id}`,
          header_token
        );
        users.push(riderDetail)
      }
      // chat
      const chatShow = []
      if (data.chat_history) {
        for (let index = 0; index < data.chat_history.length; index++) {
          const chat = data.chat_history[index];
          const user = users.find((u) => u.id === chat.userId)
          // group chat width date
          const date = moment(chat.timestamp / 1000000).format("MM/DD/YYYY");
          const time = moment(chat.timestamp / 1000000).format("HH:mm");
          const findChat = chatShow.find((c) => c.date === date)

          if (findChat === undefined) {
            chatShow.push({
              date: date,
              chats: [{ time: time, value: [{ user: user, ...chat }] }]
            })
          } else {
            const findTime = findChat.chats.find((c) => c.time === time)
            if (findTime === undefined) {
              findChat.chats.push({ time: time, value: [{ user: user, ...chat }] })
            } else {
              findTime.value.push({ user: user, ...chat })
            }
          }
        }
      }

      setChatHistory(chatShow)

      let _tmp = { ...data };

      if (data.rider === null) {
        _tmp.rider = { id: "", employee_type: {}, team: {} };
      }
      getMenu(data.car_service_id, data.car_service_addons);
      setdata(_tmp);

      const _status = order_status.filter(
        (e) => parseInt(e.value) === parseInt(data.status)
      )[0]

      setstatus(_status);
      setorder_status_filter(filterOptStatus(parseInt(data.status)))
      setIsSelectedOption(_status)

      const missingDestinetions = []
      if (data.car_service_destinations !== undefined && data.car_service_destinations.length > 0) {
        for (let index = 0; index < data.car_service_destinations.length; index++) {
          const _des = data.car_service_destinations[index];
          if (_des.delivered_date === "") {
            missingDestinetions.push({
              label: _des.address || _des.detail || "",
              value: _des.id
            })
          }
        }
      }

      setDestinations(missingDestinetions)
      setDestinationSelected(missingDestinetions.length > 0 ? missingDestinetions[0] : [])
      setReceiveImage([])
      setSignatureImage({})
      setCarServiceId(data.car_service_id)
    } catch (error) {
      console.log(error);
      swal(`${error.response}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getOrderLog = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/order/${id}/log`,
        header_token
      );

      // console.log("getOrderLog", data);
      setlog(data);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getMenu = async (car_service_id, addon_selected) => {
    try {
      let { data } = await Axios.get(
        `${api}/car-service/${car_service_id}/addon`
      );

      setaddon_option(data);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const addMenu = async (data) => {
    // console.log(data);
    try {
      let res = await Axios.post(
        `${api}/admin/order/${id}/car-addon/`,
        data,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getOrder();
        getOrderLog();
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  };

  const deleteMenu = async (addon_id) => {
    // console.log(data);
    try {
      let res = await Axios.delete(
        `${api}/admin/order/${id}/car-addon/${addon_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getOrder();
        getOrderLog();

        return true;
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
      return false;
    }
  };

  const cancelOrder = async (order_id, reason) => {
    try {
      // let res = await Axios.get(
      //   `${api}/admin/order/${order_id}/cancel`,
      //   header_token
      // );
      let res = await Axios.put(
        `${api}/admin/order/${order_id}/cancel`,
        {
          "reason": reason
        },
        header_token,
      );
      if (res.status === 200) {
        getOrder();
        swal("ยกเลิกเสร็จสิ้น !!", {
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const alertCancelOrder = () => {
    swal({
      title: `ต้องการยกเลิกออเดอร์หรือไม่ ?`,
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          placeholder: 'เหตุผลของการยกเลิก'
        },
      },
    }).then(async (willDelete) => {
      if (willDelete !== null) {
        const inputValue = swal.getState().actions.confirm.value
        if (inputValue === "" || inputValue === null) {
          swal(`กรุณากรอกเหตุผลในการยกเลิกออเดอร์`, {
            icon: "error",
            button: false,
          });
        } else {
          cancelOrder(data.id, inputValue);
        }
      }
    });
  }

  const assignOrder = async (rider_id, order_id) => {
    try {
      let send_data = {
        order_id: order_id,
        rider_id: rider_id,
      };

      let res = await Axios.post(
        `${api}/admin/order/assign/`,
        send_data,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getDataOnlineRider = async () => {
    try {
      setFilterRiderSuccessed(false)
      let send_data = {
        status: 1,
        // team_id: filter_team.value,
        role: ["rider"],
        available: "online",
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${9999999}`,
        send_data,
        header_token
      );
      // console.log(data);
      let tmp = [];
      data.users.map((val) => {
        tmp.push({ value: val.id, label: `${val.user_id} ${val.full_name}`, credit: val.credit });
      });

      setrider_option(tmp);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const confirmChangeStatus = async (status, orderId, images = [], signatureImage = "", destination_id = "", reason = "") => {
    try {
      let body = {
        destination_id: destination_id,
        images: images,
        reason: reason,
        signatureImage: signatureImage,
        status: status,
      };

      let res = await Axios.put(
        `${api}/admin/order/${orderId}`,
        body,
        header_token
      );

      if (res.status === 200) {
        return true;
      } else {
        return false;
      }

    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const filterOptStatus = (status) => {
    switch (status) {
      case 2:
        return order_status.filter((otp) =>
          otp.value !== 0 &&
          otp.value !== 1 &&
          otp.value !== 2 &&
          otp.value !== 3 &&
          otp.value !== 5 &&
          otp.value !== 6
        )
      case 3:
        return order_status.filter((otp) =>
          otp.value !== 0 &&
          otp.value !== 1 &&
          otp.value !== 2 &&
          otp.value !== 3
        )
      case 4:
        return order_status.filter((otp) =>
          otp.value !== 0 &&
          otp.value !== 1 &&
          otp.value !== 2 &&
          otp.value !== 3 &&
          otp.value !== 4 &&
          otp.value !== 6
        )

      default:
        return order_status
    }
  }

  const filterDriverHaveCredit = async (service_id) => {
    try {
      // get service detail
      let { data: carServiceDetail } = await Axios.get(
        `${api}/car-service/${service_id}`,
        header_token
      );
      // get distance in order
      const opts = carServiceDetail.service_type_option
      const distanceInOrder = data.distance
      let isWillHaveCreditMoreThan = 0
      let _percent = 0
      const _total = data.total

      // console.log(carServiceDetail.service_type_option)

      if (opts.length > 0) {
        for (let index = 0; index < opts.length; index++) {
          const otp = opts[index];
          if (distanceInOrder > otp.start_at_distance) {
            _percent = otp.commission_rate_setting[0].commission_rate
          }
        }
      }

      isWillHaveCreditMoreThan = _total * (_percent / 100)
      const filter = rider_option.filter((rider) => rider.credit >= isWillHaveCreditMoreThan)

      setrider_option(filter)
      setFilterRiderSuccessed(true)
    } catch (error) {
      console.log('filterDriverHaveCredit: ', error)
    }
  }

  useEffect(() => {
    getOrder();
    getOrderLog();
    getDataOnlineRider();
    setReceiveImage([])
    setIsShowImagePreview(false)
    setImagePreview(null)
  }, []);

  useEffect(() => {
    if (carServiceId !== null && carServiceId !== undefined && rider_option.length > 0 && !isFilterRiderSuccessed) {
      filterDriverHaveCredit(carServiceId)
    }
  }, [carServiceId, rider_option])

  return (
    <div>
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              {
                isSelectedOption !== undefined && isSelectedOption !== null && isSelectedOption.value === 4
                  ? 'เพิ่มรูปของการเข้ารับสินค้า'
                  : 'เพิ่มรูปของการส่งสินค้า'
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFileRecive" className="mb-3">
                {
                  isSelectedOption !== undefined && isSelectedOption !== null && isSelectedOption.value === 5 &&
                  <div style={{ marginBottom: 5 }}>
                    <Form.Label>
                      {'เลือกปลายทาง'}
                    </Form.Label>
                    <Select
                      value={destinationSeleted}
                      options={destinations}
                      placeholder={"ปลายทาง..."}
                      // isDisabled
                      onChange={(value) => {
                      }}
                    />
                  </div>
                }
                <Form.Label>
                  {
                    isSelectedOption !== undefined && isSelectedOption !== null && isSelectedOption.value === 4
                      ? 'รูปสิ่งของที่เข้ารับ'
                      : 'รูปสิ่งของที่ส่งสินค้า'
                  }
                </Form.Label>
                <Form.Control
                  key={'key-formFileRecive'}
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  multiple={true}
                  style={{ marginTop: 5 }}
                  onChange={async (e) => {
                    const fileList = e.target.files
                    const fileCount = e.target.files.length

                    if (fileCount > 0) {
                      const newImg = []
                      for (let index = 0; index < fileCount; index++) {
                        const img = fileList[index];
                        newImg.push({
                          preview: URL.createObjectURL(img),
                          base64: await toBase64(img),
                        })
                      }

                      setReceiveImage(newImg)
                    }
                  }}
                />
                {
                  receiveImages !== undefined && receiveImages.length > 0 && <div>
                    <Container key='img-receive-container' fluid="md" style={{ marginTop: 5 }}>
                      <Row>
                        {
                          receiveImages.map((img, index) => {
                            return <Col>
                              <Image
                                key={`img-receive-upload-${index}`}
                                src={img.preview}
                                thumbnail
                                onClick={() => {
                                  setImagePreview(img)
                                  setIsShowImagePreview(true)
                                }}
                              />
                            </Col>
                          })
                        }
                      </Row>
                    </Container>
                  </div>
                }
              </Form.Group>
              <Form.Group controlId="formFileSignatureImage" className="mb-3">
                <Form.Label>รูปลายเซ็น</Form.Label>
                <Form.Control
                  style={{ marginBottom: 5 }}
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  onChange={async (e) => {
                    if (e.target.files.length) {
                      setSignatureImage({
                        preview: URL.createObjectURL(e.target.files[0]),
                        base64: await toBase64(e.target.files[0]),
                      });
                    }
                  }}
                />
                {
                  signatureImage.preview !== undefined && <div>
                    <Image src={signatureImage.preview} thumbnail />
                  </div>
                }
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleClose}>
              ปิด
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              ยืนยัน
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <>
        <Modal show={isShowImagePreview}
          onHide={() => {
            setImagePreview(null)
            setIsShowImagePreview(false)
          }}
          size="xl"
        >
          <Modal.Body>
            {
              imagePreview !== undefined &&
              imagePreview !== null &&
              <Image src={imagePreview.preview} thumbnail />
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={() => {
              setImagePreview(null)
              setIsShowImagePreview(false)
            }}>
              ปิด
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">
              Order No.{data.order_no}
            </h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                รายการสั่งซื้อ
              </p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                Order No.{data.order_no}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">วันที่/เวลา</h6>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="input-group  d-flex align-items-center col-8">
                  <div className="input-group-addon">
                    <span className="text-muted tx-13">ออเดอร์วันที่</span>
                    <div className="input-group mb-3">
                      <input
                        id="add_name"
                        className="form-control"
                        name="add_name"
                        type="text"
                        style={{ width: 200, borderRight: 0 }}
                        value={`${moment(
                          data.created_at,
                          "YYYY/MM/DD h:mm:ss a"
                        ).format("DD/MM/YYYY")} ${moment(
                          data.created_at,
                          "YYYY/MM/DD h:mm:ss a"
                        ).format("LT")}
                        `}
                        readOnly
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{
                            backgroundColor: "#F7F9FC",
                            borderTopColor: "rgb(227, 231, 237)",
                            borderRightColor: "rgb(227, 231, 237)",
                            borderBottomColor: "rgb(227, 231, 237)",
                            borderLeft: 0,
                          }}
                        >
                          <i
                            className="fa fa-calendar-o"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group-addon mx-2 mt-3">-</div>
                  <div className="input-group-addon">
                    <span className="text-muted tx-13">วันที่ส่ง</span>

                    <div className="input-group mb-3">
                      <input
                        id="add_name"
                        className="form-control"
                        name="add_name"
                        type="text"
                        style={{ width: 200, borderRight: 0 }}
                        value={
                          data.delivered_date
                            ? `${moment(
                              data.delivered_date,
                              "YYYY/MM/DD h:mm:ss a"
                            ).format("DD/MM/YYYY")} ${moment(
                              data.delivered_date,
                              "YYYY/MM/DD h:mm:ss a"
                            ).format("LT")}
                            `
                            : data.due_date
                              ? `${moment(
                                data.due_date,
                                "YYYY/MM/DD h:mm:ss a"
                              ).format("DD/MM/YYYY")} ${moment(
                                data.due_date,
                                "YYYY/MM/DD h:mm:ss a"
                              ).format("LT")}
                            `
                              : ""
                        }
                        readOnly
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{
                            backgroundColor: "#F7F9FC",
                            borderTopColor: "rgb(227, 231, 237)",
                            borderRightColor: "rgb(227, 231, 237)",
                            borderBottomColor: "rgb(227, 231, 237)",
                            borderLeft: 0,
                          }}
                        >
                          <i
                            className="fa fa-calendar-o"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group-addon ml-3">
                    <span className="text-muted tx-13">ประเภทรถ</span>
                    <h5>{data.car_service_name}</h5>
                  </div>
                  <div className="input-group-addon ml-3">
                    <span className="text-muted tx-13">ประเภท</span>
                    <h5>{data.type === 0 ? "ออเดอร์ล่วงหน้า" : "ส่งทันที"}</h5>
                  </div>
                </div>
                <span className="ml-auto mr-3">
                  <span className="tx-13 text-muted">สถานะ</span>
                  <Select
                    isDisabled={
                      status.value === 0 ||
                      status.value === 5 ||
                      status.value === 1 ||
                      status.value === 6
                    }
                    value={status}
                    options={order_status_filter}
                    placeholder={"สถานะ.."}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        width: 150,
                      }),
                    }}
                    // isDisabled
                    onChange={(selectedOption) => {
                      if (selectedOption.value === 0) {
                        alertCancelOrder();
                      } else if (selectedOption.value === 4) {
                        // from 2 to 4
                        setIsSelectedOption(selectedOption)
                        handleShowForm()
                      } else if (selectedOption.value === 5) {
                        // from 4 to 5
                        setIsSelectedOption(selectedOption)
                        handleShowForm()
                      } else {
                        swal({
                          title: `ต้องการเปลี่ยนสถานะเป็น ${selectedOption.label} หรือไม่ ?`,
                          confirmButtonText: "ยืนยัน",
                          cancelButtonText: "ยกเลิก",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willApprove) => {
                          if (willApprove) {
                            let is_success = await confirmChangeStatus(selectedOption.value, id);

                            if (is_success) {
                              getOrder();
                              getOrderLog();
                              getDataOnlineRider();
                              swal("เสร็จสิ้น !!", {
                                icon: "success",
                              });
                            } else {
                              swal("เกิดข้อผิดพลาด !!", {
                                icon: "error",
                              });
                            }
                          }
                        });
                      }
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">สถานที่จัดส่ง</h6>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="container_timeline">
                  <div className="timeline-block timeline-block-right mb-4">
                    <div className="marker active" style={{ zIndex: 1 }}>
                      <i className="fa fa-map-marker " aria-hidden="true"></i>
                    </div>
                    <div className="timeline-content border-bottom pb-4">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col">
                              <span className="text-muted tx-13">ต้นทาง</span>
                              <br />
                              {data.car_service_start_point
                                ? data.car_service_start_point.contact_name
                                : null}
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">ที่อยู่</span>
                              <br />
                              {data.car_service_start_point
                                ? data.car_service_start_point.address
                                : null}{" "}
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">
                                เบอร์โทรศัพท์
                              </span>
                              <br />
                              {data.car_service_start_point
                                ? data.car_service_start_point.phone_number
                                : null}
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">หลักฐาน</span>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "4px",
                                  flexWrap: "wrap",
                                }}
                              >
                                {data.car_service_start_point !== null
                                  && data.car_service_start_point.picture !== undefined
                                  && data.car_service_start_point.picture.length > 0
                                  && data.car_service_start_point.picture?.map((img, i) => <>
                                    <div
                                      key={`recive-${i}-${img}`}
                                      className="thumbnail cursor-img"
                                      data-toggle="modal"
                                      data-target=".show-img"
                                      onClick={() => {
                                        setimg(
                                          img === ""
                                            ? "/images/no-img.jpg"
                                            : checkImageFlowOld(img) ? `${img_url}${img}` : img
                                        );
                                      }}
                                    >
                                      <img
                                        className="lazy img-responsive"
                                        src={
                                          img === ""
                                            ? "/images/no-img.jpg"
                                            : checkImageFlowOld(img) ? `${img_url}${img}` : img
                                        }
                                        alt="recive"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = "/images/no-img.jpg";
                                        }}
                                      />
                                    </div>
                                  </>
                                  )}
                                {
                                  data.car_service_start_point !== null
                                  && data.car_service_start_point.video !== undefined
                                  && data.car_service_start_point.video !== null
                                  && data.car_service_start_point.video.length > 0
                                  && data.car_service_start_point.video?.map((video, i) => {
                                    return <div
                                      className="thumbnail cursor-img"
                                      data-toggle="modal"
                                      data-target=".show-video"
                                      key={`recive-${i}-${video}`}
                                      onClick={() => setShowVideo(video)}
                                    >
                                      <ReactPlayer
                                        alt='Thumbnail'
                                        url={video}
                                        pip={true}
                                        width={'80px'}
                                        height={'80px'}
                                        // playing={isVideoPlaying}
                                        onPlay={() => {
                                          console.log('isPlaying!')
                                        }}
                                        onPause={() => {
                                          console.log('isPause!')
                                        }}
                                      />
                                    </div>
                                  }
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {data.car_service_destinations
                    ? data.car_service_destinations?.map((value, idx) => (
                      <div
                        className="timeline-block timeline-block-right "
                        key={shortid.generate()}
                      >
                        <div className="marker active" style={{ zIndex: 1 }}>
                          <i
                            className="fa fa-map-marker "
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div className="timeline-content border-bottom pb-5 ">
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div className="col">
                                  <span className="text-muted tx-13">
                                    ปลายทาง
                                  </span>
                                  <br />
                                  {value.contact_name}
                                  <br />
                                  <span className="tx-13 text-muted">
                                    {data.user !== null ? data.user.user_id : ''}
                                  </span>
                                </div>
                                <div className="col">
                                  <span className="text-muted tx-13">
                                    ที่อยู่
                                  </span>
                                  <br />
                                  {value.address}
                                </div>
                                <div className="col">
                                  <span className="text-muted tx-13">
                                    เบอร์โทรศัพท์
                                  </span>
                                  <br />
                                  {value.phone_number}
                                </div>
                                <div className="col" key={idx}>
                                  <span className="text-muted tx-13">หลักฐาน</span>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "4px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {value.picture !== null && value.picture.length > 0 && value.picture?.map((img, i) => <>
                                      <div
                                        key={`signature-${i}-${img}`}
                                        className="thumbnail cursor-img"
                                        data-toggle="modal"
                                        data-target=".show-img"
                                        onClick={() => {
                                          setimg(
                                            img === ""
                                              ? "/images/no-img.jpg"
                                              : checkImageFlowOld(img) ? `${img_url}${img}` : img
                                          );
                                        }}
                                      >
                                        <img
                                          className="lazy img-responsive"
                                          src={
                                            img === ""
                                              ? "/images/no-img.jpg"
                                              : checkImageFlowOld(img) ? `${img_url}${img}` : img
                                          }
                                          alt="Signature"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "/images/no-img.jpg";
                                          }}
                                        />
                                      </div>
                                    </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">พนักงานส่ง</h6>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="ml-2" style={{ width: 80 }}>
                  <div className="thumbnail">
                    <img
                      className="lazy img-responsive cursor-img"
                      data-toggle="modal"
                      data-target=".show-img"
                      onClick={() => {
                        setimg(
                          data.rider.picture === ""
                            ? "/images/no-img.jpg"
                            : checkImageFlowOld(data.rider.picture) ? `${img_url}${data.rider.picture}` : data.rider.picture
                        );
                      }}
                      src={
                        data.rider.picture
                          ? checkImageFlowOld(data.rider.picture) ? `${img_url}${data.rider.picture}` : data.rider.picture
                          : "/images/no-img.jpg"
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/no-img.jpg";
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <span className="text-muted tx-13">ชื่อนามสกุล</span>
                  <br />
                  <Link to={`/rider/history/${data.rider.id}`}>
                    <span>{data.rider.full_name}</span>
                  </Link>
                  <br />
                  <span className="tx-13 text-muted">{data.rider.user_id}</span>
                </div>
                <div className="col">
                  <span className="text-muted tx-13">เบอร์โทรศัพท์</span>
                  <br />
                  <span>{data.rider.phone_number}</span>
                </div>
                <div className="col">
                  <span className="text-muted tx-13">ประเภท</span>
                  <br />
                  <span> {data.rider.employee_type.name_th}</span>
                </div>
                <div className="col">
                  <span className="text-muted tx-13">ทีมงาน</span>
                  <br />
                  <span> {data.rider.team.name_th}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        {/* <div className={data.status === 0 ? "col-6" : "col-12"}> */}
        <div className="col-12">
          <h6 className="text-muted">สถานะการจัดส่ง</h6>
          <div className="card">
            <div className="card-body" style={{ overflowX: 'scroll' }}>
              <table id="" className="table">
                <thead>
                  <tr>
                    <th width="300">สถานะ</th>
                    <th width="200">ละติจูด</th>
                    <th width="200">ลองจิจูด</th>
                    <th width="150">วันที่</th>
                    <th width="150">เวลา</th>
                  </tr>
                </thead>
                <tbody>
                  {log?.map((value, idx) => {
                    return (
                      <tr key={shortid.generate()}>
                        <td className="name">{value.remark}</td>
                        <td>
                          <div
                            className="cursor-img"
                            data-toggle="modal"
                            data-target=".show-map"
                            onClick={() =>
                              setpoint({
                                lat: value.latitude,
                                lng: value.longitude,
                              })
                            }
                          >
                            <u>{value.latitude}</u>
                          </div>
                        </td>
                        <td>
                          <div
                            className="cursor-img"
                            data-toggle="modal"
                            data-target=".show-map"
                            onClick={() =>
                              setpoint({
                                lat: value.latitude,
                                lng: value.longitude,
                              })
                            }
                          >
                            <u>{value.longitude}</u>
                          </div>
                        </td>
                        <td className="unit">{`${moment(
                          value.created_at,
                          "YYYY/MM/DD h:mm:ss a"
                        ).format("DD/MM/YYYY")}`}</td>
                        <td className="price">
                          {moment(
                            value.created_at,
                            "YYYY/MM/DD h:mm:ss a"
                          ).format("HH:mm:ss")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* {
          data.status === 0 &&
          <div className="col-6">
            <h6 className="text-muted">เหตุผลของการยกเลิก</h6>
            <div className="card">
              <div className="card-body">
                <h5>{data.cancel_reason !== "" ? data.cancel_reason : 'ยกเลิกโดยผู้ใช้งาน'}</h5>
              </div>
            </div>
          </div>
        } */}
      </div>

      {
        data.remark !== undefined && data.remark !== null && data.remark !== '' && <div className="row mb-4">
          <div className={data.status === 0 ? "col-6" : "col-12"}>
            <h6 className="text-muted">หมายเหตุ</h6>
            <div className="card">
              <div className="card-body">
                {data.remark ?? ''}
              </div>
            </div>
          </div>
        </div>
      }

      <div className="row mb-4">
        <div className={data.status === 0 ? "col-6" : "col-12"}>
          <h6 className="text-muted">ประวัติการสนทนา</h6>
          <div className="card">
            <div className="card-body" style={{ height: '300px', overflowY: 'scroll' }}>
              {
                chatHistory.length === 0
                  ? <div style={{ width: '100%', height: '100%', textAlign: 'center' }} >ไม่มีประวัติการสนทนา</div>
                  : chatHistory.map((history, index) => {
                    return <div key={`history-${index}`}>
                      <div
                        style={{
                          fontSize: '14px',
                          width: '100%',
                          textAlign: 'center'
                        }}
                      >
                        {history.date}
                      </div>
                      {
                        history.chats.map((chat, index) => {
                          let isOldShow = ''
                          return <div
                            key={`history-chat-time-${index}`}
                            style={{
                              marginBottom: index + 1 === history.chats.length ? 0 : '10px',
                            }}
                          >
                            {
                              chat.value.map((chatVal, index) => {
                                const isRider = chatVal.user.role.name_en === 'rider'
                                const img = isOldShow === chatVal.user.role.name_en ? '' : chatVal.user.picture === ""
                                  ? "/images/no-img.jpg"
                                  : checkImageFlowOld(chatVal.user.picture) ? `${img_url}${chatVal.user.picture}` : chatVal.user.picture
                                const isEnd = index + 1 === chat.value.length
                                const timeShow = chat.time + ' น.'
                                isOldShow = isRider ? 'rider' : 'user'
                                const nextIndex = index + 1 > chat.value.length - 1 ? chat.value.length - 1 : index + 1
                                const isShowTime = nextIndex > index ? chatVal.user.role.name_en !== chat.value[nextIndex].user.role.name_en : true

                                return <div
                                  key={`history-chat-time-${chat.time}-${index}`}
                                  style={{
                                    marginBottom: isEnd ? 0 : '5px',
                                    justifyItems: isRider ? 'end' : 'start',
                                  }}
                                >
                                  <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '50%', }}>
                                    {
                                      !isRider && img !== '' && <Image
                                        key={`profile-chat-${index}`}
                                        src={img}
                                        style={{
                                          width: '35px',
                                          height: '35px',
                                          borderRadius: '35px',
                                          marginRight: '5px',
                                        }}
                                      />
                                    }
                                    <div
                                      style={{
                                        justifyItems: isRider ? 'end' : 'start',
                                        marginLeft: (!isRider && img !== '') ? 0 : '40px',
                                        marginRight: (img === '' && isRider) ? '40px' : 0,
                                      }}
                                    >
                                      <div
                                        style={{
                                          paddingTop: '7px',
                                          paddingBottom: '7px',
                                          paddingRight: '10px',
                                          paddingLeft: '10px',
                                          backgroundColor: '#f0f0f0',
                                          whiteSpace: 'pre-wrap',
                                          overflowWrap: 'break-word',
                                          borderRadius: '5px',
                                          width: 'fit-content',
                                          minWidth: '50px',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {
                                          chatVal.value === ''
                                            ? chatVal.attachment !== null
                                              ? <Image
                                                key={`profile-chat-${index}`}
                                                src={`${img_url}${chatVal.attachment}`}
                                                style={{
                                                  width: '100px',
                                                  cursor: 'pointer'
                                                }}
                                                onClick={() => window.open(`${img_url}${chatVal.attachment}`, '_blank', 'noopener,noreferrer')}
                                              />
                                              : <div
                                                style={{
                                                  cursor: 'pointer'
                                                }}
                                                onClick={() => window.open("https://maps.google.com?q=" + chatVal.location.latitude + "," + chatVal.location.longitude, '_blank', 'noopener,noreferrer')}
                                              >
                                                {chatVal.location !== null
                                                  && `ตำแหน่งปัจจุบัน (${chatVal.location.latitude + ', ' + chatVal.location.longitude})`}
                                              </div>
                                            : chatVal.value
                                        }
                                      </div>
                                      {
                                        isShowTime && <div style={{ fontSize: '14px' }}>
                                          {timeShow}
                                        </div>
                                      }
                                    </div>
                                    {
                                      isRider && img !== '' && <Image
                                        key={`profile-chat-${index}`}
                                        src={img}
                                        style={{
                                          width: '35px',
                                          height: '35px',
                                          borderRadius: '35px',
                                          marginLeft: '5px',
                                        }}
                                      />
                                    }
                                  </div>
                                </div>
                              })
                            }
                          </div>
                        })
                      }
                    </div>
                  })
              }
            </div>
          </div>
        </div>
        {
          data.status === 0 &&
          <div className="col-6">
            <h6 className="text-muted">เหตุผลของการยกเลิก</h6>
            <div className="card">
              <div className="card-body" style={{ height: '300px' }}>
                <h5>{data.cancel_reason !== "" ? data.cancel_reason : 'ยกเลิกโดยผู้ใช้งาน'}</h5>
              </div>
            </div>
          </div>
        }
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">ค่าขนส่ง</h6>
          <div className="card">
            <div className="card-body">
              {/* <div className="table-responsive"> */}
              <div className="content">
                <table id="" className="table">
                  <thead>
                    <tr>
                      <th width="600">บริการเสริม </th>
                      <th width="100">ราคา</th>
                      <th width="100">ประเภท</th>
                      <th width="150">หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menu?.map((value, index) => {
                      return (
                        <tr key={shortid.generate()}>
                          <td className="name">{value.name}</td>
                          <td>{value.price ? value.price : 0}</td>
                          <td>{value.type === 1 ? "เหมา" : ""}</td>
                          <td>{value.remark}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* </div> */}

              <div className="row" style={{ textAlign: "right" }}>
                <div className="col-12 border-bottom pt-2 pb-2">
                  <span className="h5">
                    ค่าขนส่ง ฿
                    {data.car_service_cost
                      ? data.car_service_cost.toLocaleString()
                      : "0"}
                  </span>
                </div>
                <div className="col-12 border-bottom pt-2 pb-2">
                  <span className="h5">
                    ค่าบริการเสริม ฿
                    {data.car_service_addon_cost
                      ? data.car_service_addon_cost.toLocaleString()
                      : "0"}
                    {/* {data.is_promotion_service ? `(โปรโมชั่น)` : ""} */}
                  </span>
                </div>
                {data.promotion_discount > 0 ? (
                  <div className="col-12 border-bottom pt-2 pb-2">
                    <span className="h5">
                      โปรโมชั่น {data.promotion_code ? data.promotion_code : ""}{" "}
                      ฿
                      {data.promotion_discount
                        ? data.promotion_discount.toLocaleString()
                        : "0"}
                    </span>
                  </div>
                ) : null}
                <div className="col-12 border-bottom pt-2 pb-2">
                  <span className="h5">ระยะทาง(~ {data.distance}km) </span>
                </div>
                {data.payment_method === "เงินสด" ? (
                  <>
                    <div className="col-12 border-bottom pt-2 pb-2">
                      <span className="h5">
                        เก็บเงินสด {'(' + convertPaidByText(data.paid_by) + 'เป็นผู้ชำระเงิน)'} ฿{data.total?.toLocaleString()}
                      </span>
                    </div>
                    <div className="col-12 border-bottom pt-2 pb-2">
                      <span className="h5">
                        เครดิต ฿{data.credit?.toLocaleString()}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 border-bottom pt-2 pb-2">
                      <span className="h5">เก็บเงินสด ฿0</span>
                    </div>
                    <div className="col-12 border-bottom pt-2 pb-2">
                      <span className="h5">
                        เครดิต ฿{data.credit?.toLocaleString()}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="row ml-2 mb-4">
              {data.status === 1 ||
                data.status === 2 ||
                data.status === 3 ||
                data.status === 4 ? (
                <button
                  className="btn btn-outline-primary ml-3 "
                  onClick={() => {
                    alertCancelOrder();
                  }}
                >
                  ยกเลิกออเดอร์
                </button>
              ) : (
                ""
              )}

              {data.status === 1 || data.status === 4 ? (
                <button
                  className="btn btn-outline-primary ml-2 "
                  data-toggle="modal"
                  data-target={`.assign-order`}
                  onClick={() => {
                    setselect_order({ value: data.id, label: data.order_no });
                    setorder_option([{ value: data.id, label: data.order_no }]);
                  }}
                >
                  จัดส่ง/มอบหมาย
                </button>
              ) : (
                ""
              )}
              {
                (data.status === 0 || data.status === 5) && <div
                  className="input-group-addon ml-3"
                  style={{
                    alignContent: 'center',
                  }}
                >
                  <Link
                    to={{ pathname: `/car_service_order/add`, state: { re_create: true, data: data } }}
                    className='btn btn-outline-primary ml-3'
                  >
                    <span>เรียกอีกครั้ง</span>
                  </Link>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className="modal  assign-order" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>มอบหมายงาน</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3 pt-2">
                  <label htmlFor="add_name">คำสั่งซื้อ Order No.</label>
                </div>
                <div className="col-lg-8">
                  <Select
                    value={select_order}
                    options={order_option}
                    placeholder={"เลือก..."}
                    isDisabled={true}
                  // isMulti
                  // closeMenuOnSelect={false}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3 pt-2">
                  <label htmlFor="add_name">พนักงานบริการ</label>
                </div>

                <div className="col-lg-8">
                  <Select
                    value={select_rider}
                    onChange={async (e) => {
                      setselect_rider(e);
                    }}
                    options={rider_option}
                    placeholder={"เลือก..."}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-11">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      onClick={async () => {
                        if (select_order && select_rider) {
                          let result = await assignOrder(
                            select_rider.value,
                            select_order.value
                          );

                          if (result) {
                            getOrder();
                            getOrderLog();
                            setselect_order("");
                            setselect_rider("");
                            swal("หมอบหมายงานเสร็จสิ้น !!", {
                              icon: "success",
                            });
                          } else {
                            swal("เกิดข้อผิดพลาด !!", {
                              icon: "error",
                            });
                          }
                        }
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalShowImage img={img} />

      <div className="modal  show-map" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800, height: "100%" }}>
            <div className="modal-header">
              <span>แผนที่</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <MyMapComponent point={point} />
            </div>
          </div>
        </div>
      </div>

      <div className="modal show-video" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800, height: "100%" }}>
            <div className="modal-header">
              <span>Video</span>
              <button
                type="button"
                className="close"
                onClick={() => setShowVideo('')}
                data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body"
              style={{
                display: 'flex',
                justifyContent:'center',
                alignContent: 'center'
              }}>
              <ReactPlayer
                alt='Thumbnail'
                url={showVideo}
                pip={true}
                playing={true}
                controls={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};